import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import Button from '../components/atoms/Button';

const NotFoundPage = () => (
  <Layout>
    <SEO pathname="/404/" type="website" title="Uh Oh! 404" description="404" />

    <section className="container flex flex-col items-center justify-center gap-10 py-24">
      <h2 className="text-center">
        Uh oh! Looks like that page doesn&apos;t exist.
      </h2>

      <Button label="Back to Homepage" onClick={() => navigate('/')} />
    </section>
  </Layout>
);

export default NotFoundPage;

